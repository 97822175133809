import React, { useEffect } from "react";

export const AdComponent = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-9274144256690588"
      data-ad-slot="2254787771"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};
